import React from 'react'
import { connect } from 'react-redux'
import Footer from './Footer'
import Header from './Header'
import swal from "sweetalert2"
import { getCurrentUser } from 'authentication/actions'
class PageLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: null,
    }
  }
  componentDidMount() {
    // this.props.getCurrentUser()
  }

  componentWillReceiveProps(nextProps) {
  }

  componentWillMount() {

  }

  render() {
    const { modal } = this.state
    let { home } = this.props
    return (
      <div id={`wrapper`}>
        <main>
              {this.props.children}
          <Footer />
        </main>
      </div>
    )
  }
}
const mapDispatchToProps = {
  getCurrentUser: getCurrentUser,
}
const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  location: state.location,
  home: state.home,
  profile: state.profile
})
export default connect(mapStateToProps, mapDispatchToProps)(PageLayout)