import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,
  IS_GET_API,
  SPIN_LUCKY_SUCCESS,
  GET_CONFIG_SUCCESS,
  GET_HISTORY_SUCCESS,
  CHECK_MISSION_SUCCESS,
  RELOAD_FC_SUCCESS,
  GET_ACC_REWARD_SUCCESS,
  GET_SPIN_REWARD_SUCCESS,
  GET_SPIN_SP_SUCCESS,
  EX_SP_SUCCESS,
  CLAIM_SP_SUCCESS,
  GET_ALL_REWARD_SUCCESS,
  GET_REDEEM_HIS_SUCCESS,
  GET_ACCU_HIS_SUCCESS,
} from './actions'

const initialState = {
  loading: false,
  login: false,
  user: null,
  fc: 0,
  user_status: null,
  step_infos: null,
  top_infos: null,
  history: null,
  historyShop: null,
  historyAcuu: null,
  reward_list_ex: null,
  reward_list_spin: null,
  reward_list_sp: null,
  exchange_infos: null,
  all_reward: null,
  level_infos: null,
  redeem_infos: null,
  price_infos: null,
  accumulate_infos: null,
  special_exchange_infos: null,
  special_reward_limit_infos: null,
  fc: 0,
}

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: action.payload.user_infos,
        user_status: action.payload.user_status,
        fc: action.payload.fc,
        exchange_infos: action.payload.exchange_infos,
        special_exchange_infos: action.payload.special_exchange_infos,
        special_reward_limit_infos: action.payload.special_reward_limit_infos,
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;
    case IS_GET_API:
      return {
        ...state,
        loading: true
      }
      break;
    case SPIN_LUCKY_SUCCESS:
      return {
        ...state,
        loading: false,
        fc: action.payload.fc,
        user: {
          ...state.user,
          mc: action.payload.mc,
        },
        user_status: action.payload.user_status,
        accumulate_infos: action.payload.exchange_infos,
        special_exchange_infos: action.payload.special_exchange_infos,
        special_reward_limit_infos: action.payload.special_reward_limit_infos,
      }
      break;
    case RELOAD_FC_SUCCESS:
      return {
        ...state,
        loading: false,
        fc: action.payload.fc,
      }
      break;
    case GET_ALL_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        all_reward: action.payload.reward_infos_dict,
        level_infos: action.payload.level_infos,
        price_infos: action.payload.price_infos,
      }
      break;
    case EX_SP_SUCCESS:
      return {
        ...state,
        loading: false,
        user_status: action.payload.user_status,
        redeem_infos: action.payload.redeem_infos,
      }
      break;      
    case CLAIM_SP_SUCCESS:
      return {
        ...state,
        loading: false,
        special_exchange_infos: action.payload.special_exchange_infos,
        special_reward_limit_infos: action.payload.special_reward_limit_infos,
      }
      break;
    case GET_REDEEM_HIS_SUCCESS:
      return {
        ...state,
        loading: false,
        redeem_infos: action.payload.redeem_infos,
      }
      break;
    case GET_ACCU_HIS_SUCCESS:
      return {
        ...state,
        loading: false,
        accumulate_infos: action.payload.exchange_infos,
      }
      break;
    case CHECK_MISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        user_status: action.payload.user_status,
      }
      break;
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        step_infos: action.payload.step_infos,
        top_infos: action.payload.top_infos,
      }
      break;
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload.spin_reward_infos,
        historyShop: action.payload.redeem_reward_infos,
        historyAcuu: action.payload.exchange_reward_infos,
      }
      break;
    case GET_ACC_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        reward_list_ex: action.payload.reward_infos,
      }
      break;
    case GET_SPIN_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        reward_list_spin: action.payload.reward_infos,
      }
      break;
    case GET_SPIN_SP_SUCCESS:
      return {
        ...state,
        loading: false,
        reward_list_sp: action.payload.reward_infos,
      }
      break;

    default:
      return state
  }
}
