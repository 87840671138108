import request from "lib/request"
import swal from "sweetalert2"
import { showErrorWithCode, smoothScroll, ReplaceNewString } from 'components/common'
import { setTimeout } from "timers"
export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING = 'CURRENT_USER_FETCHING'
export const IS_GET_API = 'IS_GET_API'
export const CHECK_MISSION_SUCCESS = 'CHECK_MISSION_SUCCESS'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const GET_SPIN_REWARD_SUCCESS = 'GET_SPIN_REWARD_SUCCESS'
export const GET_ACC_REWARD_SUCCESS = 'GET_ACC_REWARD_SUCCESS'
export const GET_SH_REWARD_SUCCESS = 'GET_SH_REWARD_SUCCESS'
export const GET_ALL_REWARD_SUCCESS = 'GET_ALL_REWARD_SUCCESS'
export const SPIN_LUCKY_SUCCESS = 'SPIN_LUCKY_SUCCESS'
export const RELOAD_FC_SUCCESS = 'RELOAD_FC_SUCCESS'
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS'
export const GET_SPIN_SP_SUCCESS = 'GET_SPIN_SP_SUCCESS'
export const EX_SP_SUCCESS = 'EX_SP_SUCCESS'
export const CLAIM_SP_SUCCESS = 'CLAIM_SP_SUCCESS'
export const GET_REDEEM_HIS_SUCCESS = 'GET_REDEEM_HIS_SUCCESS'
export const GET_ACCU_HIS_SUCCESS = 'GET_ACCU_HIS_SUCCESS'
export const isGetApi = () => {
  return {
    type: IS_GET_API
  }
}
//user
export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}
export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
        if (!response.payload.user_infos.account_id) {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          }).then((result) => {
            window.location.href = "/user/logout"
          })
        }
      } else {
        dispatch(getCurrentUserError(response))
        if (response.payload.error_code == 'error_account_not_found' || response.payload.error_code == 'no_account') {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          }).then((result) => {
            window.location.href = "/user/logout"
          })
        }
      }
    })
  }
}
export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}
export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response
  }
}
//check mission
export const checkMission = (isReceive) => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/user/update_win').then(function (response) {
      if (response.status == 'successful') {
        dispatch(checkMissionSuccess(response));
        if (isReceive) {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Nhận nhiệm vụ thành công.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          })
        }
        else {
          if (response.payload.user_status.mission_status == 0) {
            swal({
              title: 'Thông báo',
              html: '<p class="pop-content">Bạn chưa hoàn thành nhiệm vụ, vui lòng hoàn thành và thử lại sau.</p>',
              animation: false,
              confirmButtonText: 'Đóng',
              customClass: 'custom-modal animated zoomIn'
            })
          }
          else if (response.payload.user_status.mission_status == 1) {
            swal({
              title: 'Thông báo',
              html: '<p class="pop-content">Bạn đã hoàn thành nhiệm vụ của ngày hôm nay rồi.</p>',
              animation: false,
              confirmButtonText: 'Đóng',
              customClass: 'custom-modal animated zoomIn'
            })
          }
          else {
            swal({
              title: 'Thông báo',
              html: '<p class="pop-content">Cập nhật tiến độ nhiệm vụ thành công.</p>',
              animation: false,
              confirmButtonText: 'Đóng',
              customClass: 'custom-modal animated zoomIn'
            })
          }
        }
      } else {
        swal({
          title: 'Thông báo',
          html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      }
    })
  }
}
export const checkMissionSuccess = (response) => {
  return {
    type: CHECK_MISSION_SUCCESS,
    payload: response.payload
  }
}
//get history
export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/reward/get_user_reward_infos').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getHistoryRewardSuccess(response));
      } else {
      }
    })
  }
}
export const getHistoryRewardSuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload
  }
}
//get spin reward
export const getEventReward = (event, callBackSpin) => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/reward/get_infos_by_event?event=' + event).then(function (response) {
      if (response.status == 'successful') {
        if (event == 1) {
          dispatch(getSpinRewardSuccess(response))
          // callBackSpin()
        }
        else if (event == 2) {
          dispatch(getShopRewardSuccess(response))
        }
        else if (event == 3) {
          dispatch(getAccuRewardSuccess(response))
        }
        else if (event == 0) {
          dispatch(getAllRewardSuccess(response))
        }
        else if (event == 6) {
          dispatch(getSPRewardSuccess(response))
        }

      } else {
      }
    })
  }
}
export const getSpinRewardSuccess = (response) => {
  return {
    type: GET_SPIN_REWARD_SUCCESS,
    payload: response.payload
  }
}
export const getAccuRewardSuccess = (response) => {
  return {
    type: GET_ACC_REWARD_SUCCESS,
    payload: response.payload
  }
}
export const getShopRewardSuccess = (response) => {
  return {
    type: GET_SH_REWARD_SUCCESS,
    payload: response.payload
  }
}
export const getAllRewardSuccess = (response) => {
  return {
    type: GET_ALL_REWARD_SUCCESS,
    payload: response.payload
  }
}
export const getSPRewardSuccess = (response) => {
  return {
    type: GET_SPIN_SP_SUCCESS,
    payload: response.payload
  }
}
//lucky spin
export const doSpin = (spin_type, level, payment_type, spinAnimation) => {
  return async (dispatch, getState) => {
    dispatch(isGetApi())
    const response = await request('api/reward/spin', 'POST', {
      body: JSON.stringify({
        spin_type: spin_type,
        level: level,
        payment_type: payment_type,
      })
    })
    if (response.status == 'successful') {
      await spinAnimation(response.payload)
      dispatch(doSpinSuccess(response))
      if (response.payload.level_change == 1) {
        let stadiumname = response.payload.user_status.level
        swal({
          title: 'Thông báo',
          html: '<div class="pop-stadium-unlock"> <h5 class="st-title"> Chúc mừng bạn đã nâng cấp thành công sân vận động lên cấp ' + stadiumname + ' </h5>' +
            '<div class="box-stadium"> <h4 class="title">stadium lv.'+stadiumname+'</h4><span class="thumb"><img src="images/thumb_' + stadiumname + '.png" alt="" /></span><div/></div>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      }

    } else {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
      $('.action-btn').removeClass('prevent-click')
    }
  }
}

export const doSpinSuccess = (response) => {
  return {
    type: SPIN_LUCKY_SUCCESS,
    payload: response.payload
  }
}

//reload FC
export const reloadFC = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/user/reload_fc').then(function (response) {
      if (response.status == 'successful') {
        dispatch(reloadFCSuccess(response));
        swal({
          title: 'Thông báo',
          html: '<p class="pop-content">Cập nhật thành công.</p>',
          animation: false,
          confirmButtonText: 'Đóng',
          customClass: 'custom-modal animated zoomIn'
        })
      } else {
      }
    })
  }
}
export const reloadFCSuccess = (response) => {
  return {
    type: RELOAD_FC_SUCCESS,
    payload: response.payload
  }
}
//get config
export const getConfig = (isCall, callBackGetHis) => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/metadata/get_infos').then(function (response) {
      if (response.status == 'successful') {
        if (getState().currentUser.login) {
          console.log(getState().currentUser.currentUser.user_infos.uid)
          let result = response.payload.top_infos.find(obj => {
            return obj.uid == getState().currentUser.currentUser.user_infos.uid
          })
          if (result && isCall) {
            console.log(getState().currentUser.currentUser.user_infos.uid)
            callBackGetHis()
          }
        }
        dispatch(getConfigSuccess(response));
      } else {
      }
    })
  }
}
export const getConfigSuccess = (response) => {
  return {
    type: GET_CONFIG_SUCCESS,
    payload: response.payload
  }
}
export const getRedeemHis = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/reward/get_redeem_reward_status').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getRedeemHisSuccess(response));
      } else {
      }
    })
  }
}
export const getRedeemHisSuccess = (response) => {
  return {
    type: GET_REDEEM_HIS_SUCCESS,
    payload: response.payload
  }
}
export const getAccumulateHis = () => {
  return (dispatch, getState) => {
    dispatch(isGetApi());
    request('api/reward/get_exchange_reward_status').then(function (response) {
      if (response.status == 'successful') {
        dispatch(ggetAccumulateHisSuccess(response));
      } else {
      }
    })
  }
}
export const ggetAccumulateHisSuccess = (response) => {
  return {
    type: GET_ACCU_HIS_SUCCESS,
    payload: response.payload
  }
}

//exchange special
export const exchangeSpecial = (reward_id,amount) => {
  return async (dispatch, getState) => {
    dispatch(isGetApi())
    const response = await request('api/reward/redeem_reward', 'POST', {
      body: JSON.stringify({
        reward_id: reward_id,
        amount: amount,
      })
    })
    if (response.status == 'successful') {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">Đổi quà thành công. Quà sẽ được gửi vào hòm thư trong 24h</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
      dispatch(exchangeSpecialSuccess(response))
    } else {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
    }
  }
}
export const exchangeSpecialSuccess = (response) => {
  return {
    type: EX_SP_SUCCESS,
    payload: response.payload
  }
}


//claim special
export const claimSpecial = (reward_id) => {
  return async (dispatch, getState) => {
    dispatch(isGetApi())
    const response = await request('api/reward/claim_special_exchange', 'POST', {
      body: JSON.stringify({
        reward_id: reward_id,
      })
    })
    if (response.status == 'successful') {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">Đổi quà thành công. Quà sẽ được gửi vào hòm thư trong 24h</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
      dispatch(claimSpecialSuccess(response))
    } else {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">' + showErrorWithCode(response.error_code) + '</p>',
        animation: false,
        confirmButtonText: 'Đóng',
        customClass: 'custom-modal animated zoomIn'
      })
    }
  }
}
export const claimSpecialSuccess = (response) => {
  return {
    type: CLAIM_SP_SUCCESS,
    payload: response.payload
  }
}