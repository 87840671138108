import React from "react";
import Modal from 'components/Modal'
import moment from 'moment'
import { NavLink } from "react-router-dom";
import swal from "sweetalert2"
import Scrollbar from 'react-scrollbars-custom';
import { smoothScroll, formatNumber, img, commingSoonFnc, inGame, ReplaceNewString } from 'components/common'
import ReactTooltip from 'react-tooltip'
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      history: null,
      reward_list_spin: null,
      reward_list_ex: null,
      historyShop: null,
      historyAcuu: null,
      all_reward: null,
      histab: 1,
    }
  }
  componentDidMount() {
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.parentProp.home.history != null && !nextProps.parentProp.home.loading) {
      this.setState({
        history: nextProps.parentProp.home.history,
      })
    }
    if (nextProps.parentProp.home.historyShop != null && !nextProps.parentProp.home.loading) {
      this.setState({
        historyShop: nextProps.parentProp.home.historyShop,
      })
    }
    if (nextProps.parentProp.home.historyAcuu != null && !nextProps.parentProp.home.loading) {
      this.setState({
        historyAcuu: nextProps.parentProp.home.historyAcuu,
      })
    }

    if (nextProps.parentProp.home.fc != null && !nextProps.parentProp.home.loading) {
      this.setState({
        fc: nextProps.parentProp.home.fc
      })
    }
    if (nextProps.parentProp.home.user_status != null && !nextProps.parentProp.home.loading) {
      this.setState({
        token: nextProps.parentProp.home.user_status.token_no
      })
    }
    if (nextProps.parentProp.home.all_reward != null && !nextProps.parentProp.home.loading) {
      this.setState({
        all_reward: nextProps.parentProp.home.all_reward,
      })
    }
  }
  handleClosePopup() {
    this.setState({ modal: null })
    $('body').removeClass('modal-open');
  }
  handleOpenPopup(modal) {
    this.setState({ modal: modal })
    $('body').addClass('modal-open');
  }
  commingSoon() {
    // if (isStartEvent == 0) {
    //   commingSoonFnc()
    //   return
    // }
    window.location.href = "/user/login"
  }
  reloadFC() {
    this.props.parentProp.reloadFC()
  }
  handleViewHistory() {
    if (!this.props.parentProp.home.login) {
      swal({
        title: 'Thông báo',
        html: '<p class="pop-content">Bạn cần đăng nhập để tham gia sự kiện.</p>',
        confirmButtonText: 'Đóng',
        animation: false,
        customClass: 'custom-modal animated zoomIn'
      }).then((result) => {
        window.location.href = "/user/login"
      })
      return
    }
    this.props.parentProp.getHistory()
    this.handleOpenPopup('history')
  }
  mapEvenStatus(status) {
    if (!status) { return }
    let eventStatus;
    switch (status) {
      case 1:
        eventStatus = 'Đang xử lý'
        break;
      case 2:
        eventStatus = 'Đã gửi'
        break;
      case 3:
        eventStatus = 'Đang xử lý'
        break;
      case 4:
        eventStatus = 'Đang xử lý'
        break;
      case 5:
        eventStatus = 'Đang xử lý'
        break;
      default:
        break;
    }
    return eventStatus
  }
  render() {
    let { layout } = this.props;
    const { modal, history, reward_list_spin, reward_list_ex, fc, token, historyShop, historyAcuu, histab, all_reward } = this.state

    return (
      <React.Fragment>
        <header>
          <ul className="menu">
            <li className="btn-hd">
              <a href="javascript:void(0)" onClick={e => this.handleOpenPopup('pop-rule')}>
                {img('images/m_rule.png')}
              </a>
            </li>
            <li className="btn-gift">
              <a href="javascript:void(0)" onClick={e => this.handleOpenPopup('pop-gift')}>
                {img('images/m_gift.png')}
              </a>
            </li>

            <li className="logo">
              {
                inGame != null && inGame != undefined && inGame != '' ? (
                  <a href="javascript:void(0)"><img src="images/logo.png" alt="" /></a>
                ) : (
                  <a href="https://fo4.garena.vn/"><img src="images/logo.png" alt="" /></a>
                )
              }
            </li>
            <li className="btn-ls">
              <a href="javascript:void(0)" className="action-btn" onClick={() => this.handleViewHistory()}>
                {img('images/m_his.png')}
              </a>
            </li>
            <li className="action-l-group">
              <div className="wrap-content">
                {!this.props.currentUser.login ? (
                  <React.Fragment>
                    <a className="action" href="javascript:void(0)" onClick={e => this.commingSoon()}><span>ĐĂNG NHẬP</span></a>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className="txt-user">
                      <span>Hi </span> <b> {this.props.currentUser.currentUser.user_infos.nickname}</b>
                    </span>
                    {
                      inGame != 1 &&
                      <a className="action buttonSpin" href="/user/logout"><span>{img('images/ico_logout.png')}</span></a>
                    }

                  </React.Fragment>
                )}
              </div>
            </li>
          </ul>
          {
            this.props.currentUser.login &&
            <ul className="fc-info">
              <li>điểm đang có:&nbsp;{token}</li>
              <li>fc:&nbsp;{fc} <a href="javascript:void(0)" className="reset-fc" onClick={e => this.reloadFC()}>{img('images/refresh.png')}</a></li>
              <li><a href="https://napthe.vn/app/32837/buy/0" target="_blank">nạp fc</a></li>
            </ul>
          }
        </header>
        <Modal show={modal === 'pop-gift'} customClass={'pop-gift'} closeHandler={() => this.handleClosePopup()}>
          <h3 className="md-header">danh sách quà</h3>
          <div className="pop-img-body">
            <div className="list-all-reward">
              <div className="gift-lv-1">
                <h4 className="giftp-title">mở quà cấp 1</h4>
                <ul className="list-box-gift animated fadeInUp">
                  {
                    all_reward &&
                    all_reward.filter(item => item.event == 1).filter(item => item.level == 1).map((reward, index) => (
                      <li key={index} data-tip={reward.item_name} data-for={`tool-gift-all`}>
                        {
                          reward.token_no == 0 ? (
                            <>
                              <span className="gift">
                                <img src={ReplaceNewString(reward.item_url, '.png', '_s.png')} alt="" />
                              </span>
                              <div className="box-gift-eff">
                                {img('images/box_eff.png')}
                              </div>
                            </>
                          ) : (
                            <span className="token">
                              {img('images/token.png')}
                              <span className="count">x{reward.token_no}</span>
                            </span>
                          )
                        }
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className="gift-lv-2">
                <h4 className="giftp-title">mở quà cấp 2</h4>
                <ul className="list-box-gift animated fadeInUp">
                  {
                    all_reward &&
                    all_reward.filter(item => item.event == 1).filter(item => item.level == 2).map((reward, index) => (
                      <li key={index} data-tip={reward.item_name} data-for={`tool-gift-all`}>
                        {
                          reward.token_no == 0 ? (
                            <>
                              <span className="gift">
                                <img src={ReplaceNewString(reward.item_url, '.png', '_s.png')} alt="" />
                              </span>
                              <div className="box-gift-eff">
                                {img('images/box_eff.png')}
                              </div>
                            </>
                          ) : (
                            <span className="token">
                              {img('images/token.png')}
                              <span className="count">x{reward.token_no}</span>
                            </span>
                          )
                        }
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className="gift-lv-3">
                <h4 className="giftp-title">mở quà cấp 3</h4>
                <ul className="list-box-gift animated fadeInUp">
                  {
                    all_reward &&
                    all_reward.filter(item => item.event == 1).filter(item => item.level == 3).map((reward, index) => (
                      <li key={index} data-tip={reward.item_name} data-for={`tool-gift-all`}>
                        {
                          reward.token_no == 0 ? (
                            <>
                              <span className="gift">
                                <img src={ReplaceNewString(reward.item_url, '.png', '_s.png')} alt="" />
                              </span>
                              <div className="box-gift-eff">
                                {img('images/box_eff.png')}
                              </div>
                            </>
                          ) : (
                            <span className="token">
                              {img('images/token.png')}
                              <span className="count">x{reward.token_no}</span>
                            </span>
                          )
                        }
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className="gift-lv-4">
                <h4 className="giftp-title">quà tích luỹ</h4>
                <ul className="list-shop">
                  {
                    all_reward &&
                    all_reward.filter(item => item.event == 3 && item.reward_type != 3).map((reward, index) => (
                      <li key={index} className={``}>
                        <div className="avatar" data-tip={reward.item_name} data-for={`tool-gift-all`}>
                          <img src={reward.item_url} alt="" />
                        </div>
                        <p className="point">
                          {reward.hurdle} điểm
                                    </p>
                        {
                          reward.special_count > 0 &&
                          <span className="limited-count"> Nhận {reward.special_count} lần duy nhất</span>
                        }
                      </li>
                    ))
                  }                  
                  {
                    all_reward &&
                    all_reward.filter(item => item.reward_type == 3 && item.special_count).map((reward, index) => (
                      <li key={index} className={`special`}>
                        <div className="avatar" data-tip={reward.description} data-for={`tool-gift-all`}>
                          <img src={reward.avatar} alt="" />
                        </div>
                        <p className="point">
                          {reward.hurdle} điểm
                                    </p>
                        <span className="limited-count lineheight">Chọn 1 trong 2</span>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div className="gift-lv-5">
                <h4 className="giftp-title">quà đổi điểm</h4>
                <ul className="list-shop">
                  {
                    all_reward &&
                    all_reward.filter(item => item.event == 2).map((reward, index) => (
                      <li key={index} className={``}>
                        <div className="avatar" data-tip={reward.item_name} data-for={`tool-gift-all`}>
                          <img src={ReplaceNewString(reward.item_url, '.png', '_s.png')} alt="" />
                        </div>
                        <a href="javascrip:void(0)" className="point">
                          <span className="ex-point">
                            {reward.token_no} điểm
                                {reward.limit_per_user != 0 &&
                              <span>({reward.limit_per_user}/{reward.limit_per_user})</span>
                            }
                          </span>
                          <span className="ex-text">Đổi quà</span>
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </div>

            </div>
            {
              all_reward &&
              <ReactTooltip id="tool-gift-all" />
            }
          </div>
        </Modal>
        <Modal show={modal === 'pop-rule'} customClass={'modal-rule'} closeHandler={() => this.handleClosePopup()}>
          <h3 className="md-header">SÂN VẬN ĐỘNG TRONG MƠ</h3>
          <div className="pop-img-body">
            <div className="pop-rule">
              <div>Chào các HLV,</div>
              <div><b>"SÂN VẬN ĐỘNG TRONG MƠ"</b> vừa giúp các HLV thỏa sức xây dựng, nâng cấp “pháo đài” của riêng mình, vừa mang về loạt quà khủng như ICONS, EBS với giá cực sốc.</div>
              <span className={"text-uppercase green"}>1. Thời gian diễn ra sự kiện</span>
              <ul>
                <li>Từ ngày <b>28/10/2021</b> đến ngày <b>31/10/2021</b></li>
              </ul>
              <span className={"text-uppercase green"}>2. Hướng dẫn chơi</span>
              <ul>
                <li>Bắt đầu sự kiện với một mẫu đất nhỏ, các HLV sẽ phải xây dựng & phát triển Sân Vận Động đến cấp cao nhất để mở khóa các vật phẩm vô cùng giá trị. Sân càng lớn quà càng xịn.</li>
                <li>Tương ứng với mỗi lần nâng cấp nâng SVĐ, các HLV sẽ có cơ hội nhận được 1 phần quà cực kỳ xịn sò. </li>
                <li>Giá để nâng cấp SVĐ theo từng cấp:</li>
              </ul>
              <table>
                <thead>
                  <tr>
                    <th rowSpan="2">Cấp Sân</th>
                    <th rowSpan="2">Điều kiện lên cấp</th>
                    <th colSpan="3">Số FC để nâng cấp</th>
                  </tr>
                  <tr>
                    <th>1 lần</th>
                    <th>10 lần</th>
                    <th>50 lần</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cấp 1</td>
                    <td></td>
                    <td>10</td>
                    <td>100</td>
                    <td>500</td>
                  </tr>
                  <tr>
                    <td>Cấp 2</td>
                    <td>25 điểm cống hiến</td>
                    <td>20</td>
                    <td>190</td>
                    <td>900</td>
                  </tr>
                  <tr>
                    <td>Cấp 3</td>
                    <td>100 điểm cống hiến</td>
                    <td>40</td>
                    <td>380</td>
                    <td>1800</td>
                  </tr>
                </tbody>
              </table>
              <ul>
                <li>Ngoài các phần quà ngẫu nhiên từ các lượt nâng cấp sân,, các HLV còn có thể nhận được ngẫu nhiên một lượng điểm cống hiến.</li>
                <li>
                  Điểm cống hiến dùng để:
                <ul>
                    <li>Tích lũy nâng cấp SVĐ & nhận quà tích luỹ</li>
                    <li>Đổi quà tại Phòng Truyền Thống</li>
                  </ul>
                </li>
              </ul>
              <b> <i>Lưu ý:</i></b>
              <ul>
                <li>Điểm cống hiến sẽ mất khi trao đổi vật phẩm tại Phòng Truyền Thống</li>
                <li>Điểm cống hiến tích lũy được tính bằng tổng điểm các HLV nhận được xuyên suốt thời gian diễn ra sự kiện ( Kể cả số điểm đã mất khi trao đổi vật phẩm tại Phòng Truyền Thống)</li>
              </ul>
              <b> <i>CHÚ Ý:</i></b>
              <ul>
                <li>Quà sẽ được gửi trong vòng 1 giờ</li>
                <li>Ấn phím F5 để được cập nhật điểm FC</li>
              </ul>
            </div>
          </div>
        </Modal>
        <Modal show={modal === 'history'} customClass={'pop-history'} closeHandler={() => this.handleClosePopup()}>
          <h3 className="md-header">
            <div className="tab-his">
              <a href="javascript:void(0)" className={`${histab == 1 ? 'active' : ''}`} onClick={e => this.setState({ histab: 1 })}>quà sân vận động</a>
              <a href="javascript:void(0)" className={`${histab == 2 ? 'active' : ''}`} onClick={e => this.setState({ histab: 2 })}>phòng truyền thống</a>
              <a href="javascript:void(0)" className={`${histab == 3 ? 'active' : ''}`} onClick={e => this.setState({ histab: 3 })}>quà tích luỹ</a>
            </div>
          </h3>
          <div className="pop-img-body">
            <div className="pop-body-history">
              {
                histab == 1 &&
                <Scrollbar className={`top-all-content`} translateContentSizesToHolder={true}>
                  <table className="tbl-history">
                    <thead>
                      <tr>
                        <th>stt</th>
                        <th>quà nhận được</th>
                        <th>nhận quà từ</th>
                        <th>thời gian</th>
                        <th>trạng thái</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        history &&
                        history.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-left">
                              {
                                (item.event == 1 && item.token_no != 0) ? (
                                  <span className="item-img">{img('images/token.png')}</span>
                                ) : (
                                  <span className="item-img"><img src={ReplaceNewString(item.item_url, '.png', '_s.png')} alt="" /></span>
                                )
                              }
                              <span>{item.item_name}</span>
                            </td>
                            <td>
                              <span>  SVĐ cấp {item.level}</span>
                            </td>
                            <td>{item.create_time}</td>
                            <td>{this.mapEvenStatus(item.status)}</td>

                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </Scrollbar>
              }
              {
                histab == 2 &&
                <Scrollbar className={`top-all-content`} translateContentSizesToHolder={true}>
                  <table className="tbl-history">
                    <thead>
                      <tr>
                        <th>stt</th>
                        <th>quà nhận được</th>
                        <th>nhận quà từ</th>
                        <th>thời gian</th>
                        <th>trạng thái</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        historyShop &&
                        historyShop.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-left">
                              {
                                (item.event == 1 && item.token_no != 0) ? (
                                  <span className="item-img">{img('images/token.png')}</span>
                                ) : (
                                  <span className="item-img"><img src={ReplaceNewString(item.item_url, '.png', '_s.png')} alt="" /></span>
                                )
                              }
                              <span>{item.item_name}</span>
                            </td>
                            <td>
                              <span>Phòng Truyền Thống</span>
                            </td>
                            <td>{item.create_time}</td>
                            <td>{this.mapEvenStatus(item.status)}</td>

                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </Scrollbar>
              }
              {
                histab == 3 &&
                <Scrollbar className={`top-all-content`} translateContentSizesToHolder={true}>
                  <table className="tbl-history">
                    <thead>
                      <tr>
                        <th>stt</th>
                        <th>quà nhận được</th>
                        <th>nhận quà từ</th>
                        <th>thời gian</th>
                        <th>trạng thái</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        historyAcuu &&
                        historyAcuu.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-left">
                              <span>{item.item_name}</span>
                            </td>
                            <td>
                              <span>Quà tích luỹ</span>
                            </td>
                            <td>{item.create_time}</td>
                            <td>{this.mapEvenStatus(item.status)}</td>

                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </Scrollbar>
              }
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
